@page {
  size: A4 portrait;
  margin: 10mm;
}

@media print {
  html {
    background-color: #fff !important;
  }
  body {
    width: 190mm;
    background-color: #fff !important;
    color-adjust: exact;
    line-height: 1.5em;
  }

  .print_container {
    width: 190mm;
    box-sizing: border-box;
    page-break-after: always;
    counter-increment: print_container_count;
  }

  .recharts-wrapper,
  .recharts-surface {
    width: calc(100%) !important;
  }
}

@media screen {
  body {
    width: 100%;
    height: 100%;
    font-size: 12px !important;
  }
  .print_container {
    width: 190mm;
    min-height: 268mm;
    margin: 30px auto 300px;
    padding: 16px;
    background-color: #fff;
    filter: drop-shadow(0px 0px 5px #ddd);
    transform: scale(1.2);
    transform-origin: center top;
    page-break-after: always;
    counter-increment: print_container_count;
  }
}
