html,
body {
  background-color: #f9f9f9;
}

html,
body * {
  font-family: 'ヒラギノ丸ゴ Pro', 'M PLUS Rounded 1c', sans-serif !important;
}

/*********** Module Spacer ***********/
.module-spacer--extra-small {
  height: 12px;
}

.module-spacer--small {
  height: 20px;
}
.module-spacer--medium {
  height: 32px;
}
.module-spacer--large {
  height: 64px;
}

.module-spacer--bottom {
  padding-bottom: 4rem;
}

.full-width {
  width: 100%;
}
.module-spacer--button {
  width: 10px;
}
/*********** Custom Material Ui ***********/

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

.vertical_scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.vertical_scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.shorten_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  display: block;
}

.shorten_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@media (max-width: 1400px) {
  .shorten_title {
    max-width: 600px;
  }
}
@media (max-width: 1600px) {
  .shorten_title {
    max-width: 900px;
  }
}

@media (min-width: 1600px) {
  .shorten_title {
    max-width: 1100px;
  }
}
.delete_button {
  background-color: #ff0000 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.face_in_table {
  margin-left: 16px;
}
